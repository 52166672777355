import {
    MiraeLogo,
    TwitterLogo,
    FacebookLogo,
    InstagramLogo,
    LinkedinLogo,
    YoutubeLogo,
} from '@assets/images'
export default function Footer() {
    return (
        <div className="footer">
            <div className="">
                <img
                    alt="mirae-logo"
                    src={MiraeLogo}
                    width={0}
                    height={0}
                    className="logo"
                />
                <p>
                    Mirae Asset Sekuritas | Member of Indonesia Stock Exchange
                </p>
                <p>
                    District 8, Treasury Tower 50th floor SCBD lot 28, Jl. Jend.
                    Sudirman Kav, 52-54 Jakarta 12190
                </p>
            </div>
            {/* <div className="d-flex align-center">
                <div className="frame">
                    <img alt='mirae-facebook' className="img cursor-pointer ml-5" src={FacebookLogo} />
                    <img alt='mirae-linkedin' className="img cursor-pointer" src={LinkedinLogo} />
                    <img alt='mirae-twitter' className="img cursor-pointer ml-5" src={TwitterLogo} />
                    <img alt='mirae-youtube' className="img cursor-pointer ml-5" src={YoutubeLogo} />
                    <img alt='mirae-instagram' className="img cursor-pointer ml-8" src={InstagramLogo} />
                </div>
            </div> */}
        </div>
    )
}
