// NoMatch.js
import React from 'react'
import { GernarErrorImage } from '@assets/images'
import { useCheckTokenExpiration } from '@hooks/useCheckTokenExpiration';
import { useNavigate } from 'react-router-dom';

const NoMatch = () => {
    useCheckTokenExpiration();
    const navigate = useNavigate();
    return (
        <div className="d-flex w-100 h-100 align-center justify-center flex-column">
            <img
                src={GernarErrorImage}
                className="mt-80 w-20"
                alt="not-found"
            />
            <p>Page not found!</p>
            <button onClick={() => navigate("/")} type="submit" className="button filled mt-10">
                Home
            </button>
        </div>
    )
}

export default NoMatch
