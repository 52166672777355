import { checkTokenExpiration } from "@utils/sessionHelper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useCheckTokenExpiration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isTokenValid = checkTokenExpiration();
    if (!isTokenValid) {
      console.log("Token is invalid or expired. Please log in again.");
      navigate("/");
    }
  });
};
