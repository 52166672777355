import { Table } from '@components/molecule';
import DatePicker from 'react-datepicker';
import dailyNaviHooks from '@hooks/dailyNaviHooks';
import {
  naviDailyTransactionTableDetailFilter,
  naviDailyTransactionTableDetailHeader,
  naviDailyTransactionTableHeader,
  transactionTypeFilter,
} from '@interface/naviDailyTransaction';
import dayjs from 'dayjs';
import { idrFormatter } from '@utils/formatter';
import {
  AUALogo,
  ArrowLeft,
  Currency,
  Money,
  SearchIcon,
  SubcsriptionLogo,
  WhiteRoundCurrency,
} from '@assets/images';
import { useCheckTokenExpiration } from '@hooks/useCheckTokenExpiration';
import SortDropdown from './sortDropdown';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from '@components/atom';
import { Watermark } from '@hirohe/react-watermark';
import { forwardRef } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';

export default function NaviDailyTransactiontabData() {
  useCheckTokenExpiration();

  const {
    monthDailyNavi,
    setMonthDailyNavi,
    allDailyNaviTransactionData,
    allDailyNaviPerformanceData,
    isFetchingDailyNavi,
    showDetail,
    setShowDetail,
    naviDate,
    searchText,
    setsearchText,
    setNaviDate,
    isFetchingDailyNaviDetail,
    allDailyNaviPerformanceDetailData,
    allDailyNaviTransactionDetailData,
    setSortData,
    setTransactionType,
    detailPage,
    totalDetailPages,
    nextNaviTransactionDetail,
    setDetailPage,
  } = dailyNaviHooks();

  const handleSortData = (header: string, direction: string) => {
    setSortData(header + ' ' + direction);
  };

  const handleSortTransactionType = (header: string, direction: string) => {
    setTransactionType(header);
  };

  const InputMonth = forwardRef(({ value, onClick }: any, ref: any) => (
    <button
      className="input-calendar ml-10 cursor-pointer gap-5"
      type="button"
      onClick={onClick}
      ref={ref}
    >
      <p>{value}</p>
      <IoChevronDownOutline />
    </button>
  ));

  return (
    <div className="daily-navy">
      <Watermark
        opacity={0.3}
        textSize={16}
        text={'&#160;' + sessionStorage.getItem('fullName')}
      >
        {!showDetail ? (
          <div className="navy-header">
            <div className="box-profile">
              <span className="full-name">
                {sessionStorage.getItem('fullName') || '-'}
              </span>
              <span className="employee-id">
                {sessionStorage.getItem('employee_id') || '-'}
              </span>
            </div>
            <div className="table-filter box-date">
              <div className="navy-date-picker">
                <p>Month & Year : </p>
                <DatePicker
                  popperPlacement="bottom-start"
                  customInput={<InputMonth />}
                  className={`input-calendar ml-10 ${
                    showDetail && 'disable-input'
                  }`}
                  disabled={showDetail}
                  maxDate={new Date(dayjs().format('YYYY-MM'))}
                  minDate={
                    new Date(
                      dayjs()
                        .subtract(4, 'year')
                        .set('month', 0)
                        .format('YYYY-MM')
                    )
                  }
                  selected={new Date(monthDailyNavi)}
                  onChange={(date: any) => setMonthDailyNavi(date)}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="navy-header">
            <div className="box-profile">
              <span className="full-name">
                {sessionStorage.getItem('fullName') || '-'}
              </span>
              <span className="employee-id">
                {sessionStorage.getItem('employee_id') || '-'}
              </span>
            </div>
            <div className="header-detail">
              <div className="search-bar">
                <img src={SearchIcon} alt="Search" />
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) =>
                    setsearchText(e.target.value.replace(/\s+/g, ' '))
                  }
                  placeholder="Search YP ID or Client Name"
                  className="input-text"
                />
              </div>
              <div className="bg-white">
                <SortDropdown
                  options={naviDailyTransactionTableDetailFilter}
                  handleSortData={handleSortData}
                  defaultOption={true}
                  defaultSort={'desc'}
                  icon={<IoChevronDownOutline />}
                  sortIcon
                />
              </div>

              <div className="bg-white">
                <SortDropdown
                  label="Transaction Type"
                  sortBtn={false}
                  options={transactionTypeFilter}
                  handleSortData={handleSortTransactionType}
                  defaultOption={true}
                  icon={<IoChevronDownOutline />}
                  sortIcon
                />
              </div>

              <div className="bg-white">
                <p>Transaction Date : </p>
                <DatePicker
                  className={`input-calendar ml-10 ${
                    showDetail && 'disable-input'
                  }`}
                  disabled={showDetail}
                  maxDate={new Date(dayjs().format('YYYY-MM'))}
                  minDate={
                    new Date(
                      dayjs()
                        .subtract(4, 'year')
                        .set('month', 0)
                        .format('YYYY-MM')
                    )
                  }
                  selected={new Date(naviDate)}
                  onChange={(date: any) => setMonthDailyNavi(date)}
                  dateFormat="YYYY-MM-dd"
                  showMonthYearPicker
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className="box-container">
          {showDetail && (
            <div className="back-container">
              <div
                onClick={() => {
                  setsearchText('');
                  setDetailPage(1);
                  setShowDetail(!showDetail);
                }}
                className="navy-back"
              >
                <img src={ArrowLeft} alt="" />
                <span>Back</span>
              </div>
              <div className="box-title">
                <div className="img">
                  <img src={WhiteRoundCurrency} alt="" />
                </div>
                <span>Detail Transaction</span>
              </div>
            </div>
          )}
          <div className="main-bg ph-8 navitab ">
            {!showDetail ? (
              <div className="navitab-container">
                <div className="title-section">
                  <img
                    alt="Fund Performance"
                    className="img-navitab cursor-pointer ml-5"
                    src={Money}
                  />
                  <p className="navitab-title">Fund Performance</p>
                </div>
                <div className="navitab-item">
                  <div className="item-title-container">
                    <img
                      alt="AUA"
                      className="img cursor-pointer ml-5"
                      src={AUALogo}
                    />
                    <p className="item-title">AUA</p>
                  </div>
                  <p className="item-value">
                    {idrFormatter(allDailyNaviPerformanceData?.aua)}
                  </p>
                </div>
                <div className="navitab-item">
                  <div className="item-title-container">
                    <img
                      alt="Net Transaction"
                      className="img cursor-pointer ml-5"
                      src={Currency}
                    />
                    <p>Net Transaction</p>
                  </div>
                  <p className="item-value">
                    {idrFormatter(allDailyNaviPerformanceData?.netTransaction)}
                  </p>
                </div>
                <div className="navitab-item">
                  <div className="item-title-container">
                    <img
                      alt="Subscriptions"
                      className="img cursor-pointer ml-5"
                      src={SubcsriptionLogo}
                    />
                    <p>Subscriptions</p>
                  </div>
                  <p className="item-value">
                    {idrFormatter(allDailyNaviPerformanceData?.subscription)}
                  </p>
                </div>
                <div className="navitab-item">
                  <div className="item-title-container">
                    <img
                      alt="Redemption"
                      className="img cursor-pointer ml-5"
                      src={SubcsriptionLogo}
                    />
                    <p>Redemption</p>
                  </div>
                  <p className="item-value">
                    {idrFormatter(allDailyNaviPerformanceData?.redemption)}
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className="navitab-container">
                  <div className="title-section">
                    <img
                      alt="Fund Performance"
                      className="img-navitab cursor-pointer ml-5"
                      src={Money}
                    />
                    <p className="navitab-title">Fund Performance</p>
                  </div>
                  <div className="navitab-item">
                    <div className="item-title-container">
                      <img
                        alt="Net Transaction"
                        className="img cursor-pointer ml-5"
                        src={Currency}
                      />
                      <p>Net Transaction</p>
                    </div>
                    <p className="item-value">
                      {idrFormatter(
                        allDailyNaviPerformanceDetailData?.netTransaction
                      )}
                    </p>
                  </div>
                  <div className="navitab-item">
                    <div className="item-title-container">
                      <img
                        alt="Subscriptions"
                        className="img cursor-pointer ml-5"
                        src={SubcsriptionLogo}
                      />
                      <p>Subscriptions</p>
                    </div>
                    <p className="item-value">
                      {idrFormatter(
                        allDailyNaviPerformanceDetailData?.subscription
                      )}
                    </p>
                  </div>
                  <div className="navitab-item">
                    <div className="item-title-container">
                      <img
                        alt="Redemption"
                        className="img cursor-pointer ml-5"
                        src={SubcsriptionLogo}
                      />
                      <p>Redemption</p>
                    </div>
                    <p className="item-value">
                      {idrFormatter(
                        allDailyNaviPerformanceDetailData?.redemption
                      )}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>

          {!showDetail && (
            <div
              className="table-container column-hover daily-radius"
              style={{
                minHeight: allDailyNaviTransactionData.length < 1 ? '65vh' : '',
              }}
            >
              <Table
                isFetching={isFetchingDailyNavi}
                header={naviDailyTransactionTableHeader}
                onClickItem={(item) => {
                  setShowDetail(!showDetail);
                  setNaviDate((prevState) => item[1]);
                }}
                data={allDailyNaviTransactionData}
                numberColumn={[2, 3, 4]}
              />
            </div>
          )}

          {showDetail && (
            <>
              <div className="navi-table-container navi-detail">
                <InfiniteScroll
                  dataLength={allDailyNaviTransactionDetailData.length}
                  next={nextNaviTransactionDetail}
                  hasMore={
                    !isFetchingDailyNaviDetail && detailPage <= totalDetailPages
                  }
                  loader={<Spinner />}
                  height={'70vh'}
                >
                  <Table
                    isFetching={isFetchingDailyNaviDetail}
                    header={naviDailyTransactionTableDetailHeader}
                    onClickItem={(e) => {}}
                    data={allDailyNaviTransactionDetailData}
                    numberColumn={[1, 7, 8, 9]}
                    highlightSearch={[2]}
                    searchText={searchText}
                  />
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      </Watermark>
    </div>
  );
}
