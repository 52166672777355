import dayjs from "dayjs";
import { api } from "./config";

interface fetchPortofolioPerformanceInterface {
  page: number;
  query: string;
  sort?: string;
  stock?: string;
  bond?: string;
}

interface fetchPortofolioPerformanceStockInterface {
  ypId: string;
  page: number;
  query: string;
  sort?: string;
}

interface fetchPortfolioPerformanceBondInterface {
  ypId: string;
  page: number;
  sort: string;
  limit: number;
}

// https://api.dev.miraeasset.io/v1/pls/backend/portfolio-performances/summary?sort=Name%20Asc&limit=100&query=ANTHONY%27

export const fetchPortofolioPerformance = async (
  props: fetchPortofolioPerformanceInterface
) => {
  try {
    const response = await api.get("/v1/pls/backend/portfolio-performances/summary",
      {
      params: {
        scrollId: props.page,
        query: props.query,
        sort: props.sort,
        stockCode: props?.stock,
        bondCode: props?.bond,
      }
     }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchStockListAll = async () => {
  try {
    const response = await api.get(
        "/v1/pls/backend/search/stock",
        {
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBondListAll = async () => {
  try {
    const response = await api.get("/v1/pls/backend/search/bond");
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPortofolioPerformanceStock = async (
  props: fetchPortofolioPerformanceStockInterface
) => {
  try {
    const response = await api.get("/v1/pls/backend/stock-data/summary",
   {
      params: {
        ypid: props.ypId,
        scrollId: props.page,
        // query: props.query,
        sort: props.sort,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPortofolioPerformanceFund = async (
  props: fetchPortofolioPerformanceStockInterface
) => {
  try {
    const response = await api.get("/v1/pls/backend/fund-data/summary",
      {
      params: {
        ypid: props.ypId,
        scrollId: props.page,
        // query: props.query,
        sort: props.sort,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPortofolioPerformanceBond = async (
  props: fetchPortfolioPerformanceBondInterface
) => {
  try {
    const response = await api.get("/v1/pls/backend/bond-data/summary",
      {
      params: {
        ypid: props.ypId,
        scrollId: props.page,
        sort: props.sort,
        limit: props.limit,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};