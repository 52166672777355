import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  DailyNavyTransaction,
  Home,
  Login,
  NoMatch,
  PortfolioPerformance,
  SummaryInvesting,
} from 'pages';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import {
  clarityScript,
  googleAnalyticsScript,
  googleAnalyticsInitScript,
} from './config/scripts.js';
import { useEffect } from 'react';
import { SidebarProvider } from 'context/sidebarContext';

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_DISABLED_RIGHT_CLICK === '1') {
      document.addEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    }
    if (process.env.REACT_APP_DISABLED_KEYBOARD_SHORT_CUT === '1') {
      document.addEventListener(
        'copy',
        (e) => {
          e.preventDefault();
        },
        false
      );

      document.addEventListener('keydown', (e) => {
        if (
          (e.ctrlKey && e.shiftKey && e.key === 'I') || // Ctrl+Shift+I
          (e.ctrlKey && e.shiftKey && e.key === 'C') || // Ctrl+Shift+C
          (e.ctrlKey && e.shiftKey && e.key === 'J') || // Ctrl+Shift+J
          (e.ctrlKey && e.key === 'U') || // Ctrl+U
          e.key === 'F12'
        ) {
          // F12
          e.preventDefault();
        }
      });
    }
  }, []);

  return (
     <SidebarProvider>
    <Router>
      <Helmet>
        <script>{clarityScript}</script>
        <script src={googleAnalyticsScript} async></script>
        <script>{googleAnalyticsInitScript}</script>
      </Helmet>
      <main role="main" className="container">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/summary-investing" element={<SummaryInvesting />} />
          <Route path="/daily-navy" element={<DailyNavyTransaction />} />
          <Route
            path="/portfolio-performance"
            element={<PortfolioPerformance />}
          />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </main>
    </Router>
    </SidebarProvider>
  );
};

export default App;
