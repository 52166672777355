import { Main } from '@components/layout';
import { SummaryInvestingContent } from '@components/organism';
import React from 'react';

const SummaryInvesting = () => {
  return (
    <Main>
      <SummaryInvestingContent />
    </Main>
  );
};

export default SummaryInvesting;
