export const naviDailyTransactionTableHeader = [
  "No",
  "Transaction Date",
  "Net Transaction",
  "Subscription",
  "Redemption",
];

export const naviDailyTransactionTableDetailHeader = [
  "No",
  "YP ID",
  "Client Name",
  "Branch",
  "Manager",
  "Fund Code",
  "Fund",
  "Unit",
  "NAV Per Unit",
  "Amount",
  "Transaction Type",
];

export const transactionTypeFilter = [
  { label: "No", field: "No" },
  { label: "All", field: "All", default: true },
  { label: "Subscription", field: "Subscription" },
  { label: "Redemption", field: "Redemption" },
];

export const naviDailyTransactionTableDetailFilter = [
  { label: "No", field: "No" },
  { label: "YP ID", field: "YpId" },
  { label: "Client Name", field: "Name" },
  { label: "Branch", field: "BranchName" },
  { label: "Manager", field: "ManagerName" },
  { label: "Fund Code", field: "FundCode" },
  { label: "Fund", field: "Fund" },
  { label: "Unit", field: "Unit" },
  { label: "NAV Per Unit", field: "NavPerUnit" },
  { label: "Amount", field: "Amount", default: true },
  { label: "Transaction Type", field: "TransactionType" },
];

export interface naviDailyTransactionInterface {
  netTransaction: number;
  subscription: number;
  redemption: number;
  transactionDate: string;
}

export interface naviDailyTransactionDetailInterface {
  ypId: string;
  name: string;
  branchName: string;
  managerName: string;
  fundCode: string;
  fund: string;
  unit: string;
  varPerUnit: string;
  amount: number;
  transactionType: string;
}
