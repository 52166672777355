import { useEffect, useState } from "react";
import { Watermark } from "@hirohe/react-watermark";
import { useCheckTokenExpiration } from "@hooks/useCheckTokenExpiration";
import PortofolioPerformanceTabData from "@components/organism/portofolioPerformanceTabData";
import SummartInvTransactionTabData from "@components/organism/summartInvTransactionTabData";
import NaviDailyTransactionTabData  from "@components/organism/naviDailyTransactionTabData";

export default function Home() {
  useCheckTokenExpiration();

  const allMenu = [
    "Summary Investing Performance",
    "Daily Navi Transactions",
    "Portfolio Performance",
  ];
  const [activeMenu, setActiveMenu] = useState(0);

  const handleOnClickTab = (index: number) => {
    setActiveMenu(index);
  };

  return (
    <div className="home-container container">
      <div className="d-flex">
        <div className="home-tab-menu">
          {allMenu.map((item, index) => (
            <button
              onClick={() => handleOnClickTab(index)}
              key={index}
              className={
                activeMenu === index
                  ? "tab-button filled"
                  : "tab-button outlined"
              }
            >
              {item}
            </button>
          ))}
        </div>
      </div>
      <Watermark
        opacity={0.3}
        textSize={16}
        text={"&#160;" + sessionStorage.getItem("fullName") ?? ""}
      >
        <div
          className="container bg-white p-12 mt-16 border-radius shadow-main home-table-box"
          onCopy={() => {
            return false;
          }}
        >
          {activeMenu === 0 && <SummartInvTransactionTabData />}
          {activeMenu === 1 && <NaviDailyTransactionTabData />}
          {activeMenu === 2 && <PortofolioPerformanceTabData />}
        </div>
        {/* <div className="home-modal">
                <div className="modal-container">
                    <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Eius fuga maiores mollitia labore, eos, cum
                        consequuntur voluptas iusto temporibus animi cumque
                        quaerat repellat perspiciatis quas necessitatibus esse
                        quis optio voluptatibus. Lorem ipsum dolor sit amet
                        consectetur, adipisicing elit. Consequatur, itaque.
                        Voluptatum veritatis provident soluta dolorum illum?
                        Veritatis laborum adipisci earum consectetur. Quidem
                        aperiam consequatur tempora excepturi ab laudantium
                        quae. Eos.
                    </p>
                    <p>asd</p>
                </div>
            </div> */}
      </Watermark>
    </div>
  );
}
