interface spinnerInterface {
  label: string;
}

const SmSpinner: React.FC<spinnerInterface> = ({ label }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className="loader"></span>
      <span style={{ paddingLeft: "3px" }}>{label}</span>
    </div>
  );
};

export default SmSpinner;
