const getSessionStorageItem = (key: string) => {
  const itemStr = sessionStorage.getItem(key);
  // If the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const now = new Date();
  // Compare the expiry time of the item with the current time
  if (now.getTime() > parseInt(itemStr)) {
    // If the item is expired, remove it and return null
    sessionStorage.clear();
    return null;
  }

  return true;
};

export const checkTokenExpiration = () => {
  const accessToken = getSessionStorageItem("expires_in");
  return !accessToken ? false : true;
};
