import { Main } from '@components/layout';
import { NaviDailyTransactionContent } from '@components/organism';
import React from 'react';

const DailyNavyTransaction = () => {
  return (
    <Main>
      <NaviDailyTransactionContent />
    </Main>
  );
};

export default DailyNavyTransaction;
