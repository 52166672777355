import {
  naviDailyTransactionDetailInterface,
  naviDailyTransactionInterface,
} from '@interface/naviDailyTransaction';
import {
  allBondInterface,
  allStockInterface,
  portfolioPerformanceBondInterface,
  portofolioPerformanceFundInterface,
  portofolioPerformanceInterface,
  portofolioPerformanceStockInterface,
} from '@interface/portofolioPerformance';
import {
  summaryInvestingInterface,
  summaryInvestingInterfaceWindex,
} from '@interface/summaryInvesting';
import dayjs from 'dayjs';

export const numberFormatter = (number: any) => {
  if (typeof number === 'string') {
    number = parseFloat(number);
    if (isNaN(number)) return '0';
  } else if (typeof number !== 'number') {
    return '0';
  }

  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

export const checkNumberPositiveOrNegative = (number: any) => {
  const numberValue = parseInt(number);

  if (Math.sign(numberValue) === 1) {
    return 'positive';
  } else if (Math.sign(numberValue) === -1) {
    return 'negative';
  } else {
    return 'zero';
  }
};

export const idrFormatter = (number: any) => {
  if (typeof number !== 'number') {
    return '0';
  } else {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(number);
  }
};

export const dateFormat1 = (date: any) => {
  var dateObj = new Date(date);
  try {
    let newDate = dateObj.toLocaleString('en-uk', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    return newDate.toString();
  } catch (error) {
    return dateObj.toDateString();
  }
};

const formatDate = (date: string | null): string =>
  date ? dayjs(date).format('DD MMM YY') : '-';

export const toCamelize = (str: string) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const getColor = (value: string): string => {
  const numberValue = parseFloat(value.replace(/,/g, ''));
  if (numberValue > 0) return 'green';
  if (numberValue < 0) return 'red';
  return 'black';
};

export const highlightSearchText = (text: string, searchTerm: string) => {
  if (!searchTerm.trim() || searchTerm.trim().length < 3) return text;
  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <strong key={index} style={{ fontWeight: 'bold' }}>
        {part}
      </strong>
    ) : (
      part
    )
  );
};

export const capitalize = (str: string) => {
  if (str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLocaleLowerCase();
  } else {
    return '';
  }
};

export const sumInvtoArrString = (
  data: summaryInvestingInterface[],
  initialDataLength: number
) => {
  const result: string[][] = [];
  if (data) {
    data.forEach((e, index) =>
      result.push([
        (index + 1 + initialDataLength).toString(),
        e.ypId,
        toCamelize(e.name),
        toCamelize(e.branchName),
        toCamelize(e.managerName),
        dayjs(e.estDate).format('DD MMM YYYY'),
        e.contactDate ? dayjs(e.contactDate).format('DD MMM YYYY') : '-',
        numberFormatter(e.initialDeposit),
        numberFormatter(e.transactionValue),
        numberFormatter(e.fee),
        numberFormatter(e.aua),
        numberFormatter(e.bonds),
        numberFormatter(e.rdnCash),
        e.naviRegistration
          ? dayjs(e.naviRegistration).format('DD MMM YYYY')
          : '-',
        numberFormatter(e.assetValue),
      ])
    );
  }
  return result;
};

export const sumInvtoArrStringWindex = (
  data: summaryInvestingInterfaceWindex[]
) => {
  const result: string[][] = [];
  if (data) {
    data.forEach((e) =>
      result.push([
        e.no + '',
        e.ypId,
        toCamelize(e.name),
        toCamelize(e.branchName),
        toCamelize(e.managerName),
        dayjs(e.estDate).format('DD MMM YYYY'),
        e.contactDate ? dayjs(e.contactDate).format('DD MMM YYYY') : '-',
        numberFormatter(e.initialDeposit),
        numberFormatter(e.transactionValue),
        numberFormatter(e.fee),
        numberFormatter(e.aua),
        numberFormatter(e.bonds),
        numberFormatter(e.rdnCash),
        e.naviRegistration
          ? dayjs(e.naviRegistration).format('DD MMM YYYY')
          : '-',
        numberFormatter(e.assetValue),
      ])
    );
  }
  return result;
};

export const naviDailyArrString = (
  data: naviDailyTransactionInterface[],
  initialDataLength: number
) => {
  const result: string[][] = [];
  data.forEach((e, index) =>
    result.push([
      (index + 1 + initialDataLength).toString(),
      dayjs(e.transactionDate).format('MMM D, YYYY'),
      numberFormatter(e.netTransaction),
      numberFormatter(e.subscription),
      numberFormatter(e.redemption),
    ])
  );
  return result;
};

export const portofolioPerformanceArrString = (
  data: portofolioPerformanceInterface[],
  initialDataLength: number
) => {
  const result: string[][] = [];
  data.forEach((e, index) =>
    result.push([
      (index + 1 + initialDataLength).toString(),
      e.ypid,
      toCamelize(e.name),
      numberFormatter(e.stockAssetValue) +
        ` (${e.stockAssetProfitLossPercentage}%) ${
          parseFloat(e.stockAssetProfitLossPercentage) === 0
            ? ''
            : parseFloat(e.stockAssetProfitLossPercentage) > 0
            ? '↗'
            : '↘'
        }`,
      // numberFormatter(e.bondsAssetValue),
      numberFormatter(e.fundAssetValue) +
        ` (${e.fundAssetProfitLossPercentage}%) ${
          parseFloat(e.fundAssetProfitLossPercentage) === 0
            ? ''
            : parseFloat(e.fundAssetProfitLossPercentage) > 0
            ? '↗'
            : '↘'
        }`,
      numberFormatter(e.bondsNominal),
    ])
  );
  return result;
};

export const portofolioPerformanceStockArrString = (
  data: portofolioPerformanceStockInterface[],
  initialDataLength: number
) => {
  const result: string[][] = [];
  data.forEach((e, index) =>
    result.push([
      (index + 1 + initialDataLength).toString(),
      numberFormatter(e.asset_value),
      numberFormatter(e.avgPrice),
      numberFormatter(e.last_price),
      numberFormatter(e.lot),
      numberFormatter(e.profit_loss.toString()),
      numberFormatter(e.profit_loss_percentage) + '%',
    ])
  );
  return result;
};

export const portofolioPerformanceFundArrString = (
  data: portofolioPerformanceFundInterface[],
  initialDataLength: number
) => {
  const result: string[][] = [];
  data.forEach((e, index) =>
    result.push([
      (index + 1 + initialDataLength).toString(),
      e.product_name,
      numberFormatter(e.unit),
      numberFormatter(e.avg_price),
      numberFormatter(e.nav),
      numberFormatter(e.asset_value),
      numberFormatter(e.profit_loss),
      numberFormatter(e.profit_loss_percentage) + '%',
    ])
  );
  return result;
};

export const portfolioPerformanceBondArrString = (
  data: portfolioPerformanceBondInterface[],
  initialDataLength: number
): string[][] => {
  return data.map((e, index) => [
    (index + 1 + initialDataLength).toString(),
    e.item_code || '-',
    numberFormatter(e.nominal),
    numberFormatter(e.coupon_pa),
    formatDate(e.maturity_date),
    numberFormatter(e.customer_value),
    numberFormatter(e.ibpa_price),
    numberFormatter(e.current_value),
    numberFormatter(e.holding_periods),
    numberFormatter(e.interest_gain),
    numberFormatter(e.capital_gain),
    numberFormatter(e.return_value) +
      ` (${e.return_percentage.toString().replace('-', '')}%)`,
  ]);
};

export const allStockArrString = (data: allStockInterface[]) => {
  const result: { field: string; label: string }[] = [];
  data.forEach((e, index) =>
    result.push({ field: e.stockCode, label: e.stockCode })
  );
  return result;
};

export const allBondArrString = (data: allBondInterface[]) => {
  const result: { field: string; label: string }[] = [];
  data.forEach((e, index) =>
    result.push({ field: e.bond_code, label: e.bond_code })
  );
  return result;
};

export const naviDailyDetailArrString = (
  data: naviDailyTransactionDetailInterface[],
  initialDataLength: number
) => {
  const result: string[][] = [];
  data.forEach((e, index) =>
    result.push([
      (index + 1 + initialDataLength).toString(),
      e.ypId,
      toCamelize(e.name),
      toCamelize(e.branchName),
      toCamelize(e.managerName),
      e.fundCode,
      toCamelize(e.fund),
      e.unit,
      e.varPerUnit,
      numberFormatter(e.amount),
      toCamelize(e.transactionType),
    ])
  );
  return result;
};
