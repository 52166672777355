import React from "react";
import { LoginFormCard } from "@components/card";
import { LoginDots, LoginThumbnail } from "@assets/images";
import { Main } from "@components/layout";

export default function Login() {
  return (
    <Main>
    <div
      className="login-page d-flex justify-space-between mt-50"
      style={{ padding: "0px 114px" }}
    >
      <img
        alt="mirae-login-portal"
        className="login-shadow-image"
        src={LoginDots}
      />
      <LoginFormCard />
      <img
        alt="mirae-login-portal"
        className="login-thumbnail"
        src={LoginThumbnail}
      />
    </div>
    </Main>
  );
}
