import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_WEB_PORTALIS_BASE_URL;

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

let ipAddress: string | null = null;

const fetchIPAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    ipAddress = response.data.ip || 'N/A';
  } catch (error) {
    console.error('Error fetching the IP address:', error);
    ipAddress = 'N/A';
  }
};

fetchIPAddress();

api.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem('access_token');
    const applicationId = 'Portalis';

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (!ipAddress || ipAddress === 'N/A') {
      await fetchIPAddress();
    }

    config.headers['IP-Address'] = ipAddress || 'N/A';
    config.headers['Application-Id'] = applicationId;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
