import { CloseIcon, ConfirmIllustration } from '@assets/images';
import { Loader, Modal } from 'rsuite';

interface Props {
  isShow: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
  errorMessage?: string;
}

export default function PopUpConfirm(props: Props) {
  return (
    <Modal
      keyboard={false}
      open={props.isShow}
      onClose={props.onClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
      }}
    >
      <Modal.Body style={{ width: '250px' }}>
        {!props.loading ? (
          <div
            style={{
              justifyContent: 'end',
              display: 'flex',
              padding: '10px',
              cursor: 'pointer',
            }}
            onClick={props.onClose}
          >
            <img src={CloseIcon} alt="close" />
          </div>
        ) : null}
        <div
          style={{ justifyContent: 'center', display: 'flex', padding: '10px' }}
        >
          <img src={ConfirmIllustration} alt="confirm" />
        </div>

        <div className="confirm-content">
          <p>The consumer's first call can only be written and saved once.</p>
          <span>Have you entered the data correctly?</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="confirm-footer">
          <button
            disabled={props.loading}
            className="button-primary"
            onClick={() => props.onConfirm()}
          >
            Yes
            {props.loading ? <Loader /> : null}
          </button>
          <button className="button-outlined" onClick={() => props.onClose()}>
            No
          </button>
        </div>
        {props.errorMessage ? (
          <p className="error">
            {props.errorMessage ? props.errorMessage : null}
          </p>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}
