import { Main } from '@components/layout';
import { PortfolioPerformanceContent } from '@components/organism';
import React from 'react';

const PortfolioPerformance = () => {
  return (
    <Main>
      <PortfolioPerformanceContent />
    </Main>
  );
};

export default PortfolioPerformance;
