interface props {
  bgcolor: string
  completed: number
}

export default function progressbar(props: props) {

  const containerStyles = {
    height: 16,
    width: '100%',
    backgroundColor: "#ECEFF4",
    borderRadius: 4,
  }

  const fillerStyles = {
    height: '100%',
    width: `${props.completed}%`,
    backgroundColor: props.bgcolor,
    borderRadius: 'inherit',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  }
  return <div style={containerStyles}>
    <div style={fillerStyles} >
      <span style={labelStyles}></span>
    </div>
  </div>

}


