import React from 'react'
import { DataNotFound, DotsIcon } from '@assets/images'
import { Spinner } from '@components/atom'
import { sumInvTopBottom } from '@interface/summaryInvesting'

interface props {
    header: string[]
    data: sumInvTopBottom
    flex?: string
    onClickItem?: (item: string[]) => void
    isFetching?: boolean
    widthColumns?: string[];
}
export default function Table(props: props) {
    return (
        <>
            <div className='table-container'>
                <table className="table table-top-bottom">
                    <thead>
                        <tr className="th-container">
                            {props.header.map((item, index) => (
                                <th key={index} align="left" style={{ minWidth: props.widthColumns?.[index] || undefined }}>
                                    {item}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.top.map((item, index) => (
                            <tr
                                key={index}
                                className="cursor-pointer table-green"
                                onClick={() =>
                                    props.onClickItem && props.onClickItem(item)
                                }
                            >
                                {item.map((childItem, childIndex) => (
                                    <td key={childIndex}>{childItem}</td>
                                ))}
                            </tr>
                        ))}
                        {!props.isFetching && props.data.top.length >= 1 && (
                            <tr className="table-grey">
                                {props.header.map((_, index) => (
                                    <td key={index} align="left">
                                        <img
                                            src={DotsIcon}
                                            className="table-top-bottom-image-separator"
                                            alt="mirae table separator"
                                        />
                                    </td>
                                ))}
                            </tr>
                        )}
                        {!props.isFetching && props.data.bottom.length >= 1 && (
                            <tr className="table-grey">
                                {props.header.map((_, index) => (
                                    <td key={index} align="left">
                                        <img
                                            src={DotsIcon}
                                            className="table-top-bottom-image-separator"
                                            alt="mirae table separator"
                                        />
                                    </td>
                                ))}
                            </tr>
                        )}
                        {props.data.bottom.map((item, index) => (
                            <tr
                                key={index}
                                className="cursor-pointer table-red"
                                onClick={() =>
                                    props.onClickItem && props.onClickItem(item)
                                }
                            >
                                {item.map((childItem, childIndex) => (
                                    <td key={childIndex}>{childItem}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Spinner show={props.isFetching} />
            </div>
            
            {!props.isFetching && props.data.top.length < 1 && props.data.bottom.length < 1 && (
                <div className='table-empty'>
                    <img src={DataNotFound} alt="" />
                    <p>Data Not Found</p>
                </div>
            )}
        </>
    )
}
