import {
  portofolioPerformanceAccountInfoInterface,
  portofolioPerformanceInterface,
} from "@interface/portofolioPerformance";
import {
  fetchBondListAll,
  fetchPortofolioPerformance,
  fetchPortofolioPerformanceBond,
  fetchPortofolioPerformanceFund,
  fetchPortofolioPerformanceStock,
  fetchStockListAll,
} from "@services/portofolioApi";
import { dummmyPortofolio } from "@utils/dummy";
import {
  allStockArrString,
  portofolioPerformanceArrString,
  portfolioPerformanceBondArrString,
  portofolioPerformanceFundArrString,
  portofolioPerformanceStockArrString,
  allBondArrString,
} from "@utils/formatter";
import { isStringExist } from "@utils/validation";
import { useEffect, useRef, useState } from "react";
import useDebounce from "./useDebounce";
import { apiErrorHandler } from "@utils/errorHandler";

const PortoPerformanceHooks = () => {
  const [stockList, setStockList] = useState<
    { label: string; field: string }[]
  >([]);
  const [bondList, setBondList] = useState<
    { label: string; field: string }[]
  >([]);
  const [allData, setAllData] = useState<string[][]>([]);
  const [allDataDetail, setAllDataDetail] = useState<string[][]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingDetail, setIsFetchingDetail] = useState(true);
  const [fetchingPage, setFetchingPage] = useState(1);
  const [fetchingPageDetail, setFetchingPageDetail] = useState(1);
  const [searchText, setSearchText] = useState("");
  const debounceSearchText = useDebounce(searchText, 500);
  const [sortData, setSortData] = useState("YpId asc");
  const [sortDataStock, setSortDataStock] = useState("");
  const [sortDataFund, setSortDataFund] = useState("");
  const [maxFetchingPage, setMaxFetchingPage] = useState(1);
  const [maxFetchingPageDetail, setMaxFetchingPageDetail] = useState(1);
  const [fetchigErrorType, setfetchigErrorType] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [accountInfo, setAccountInfo] =
    useState<portofolioPerformanceAccountInfoInterface>();
  const [selectedStock, setSelectedStock] = useState("");
  const [selectedBond, setSelectedBond] = useState("")

  const [detailType, setDetailType] = useState("stock");

  const [ypId, setYpId] = useState("");

  const [fundData, setFundData] = useState<string[][]>([]);
  const [isFetchFund, setIsFetchFund] = useState(true);
  const [fundPage, setFundPage] = useState(1);
  const [maxFundPage, setMaxFundPage] = useState(1);
  const [errorFetchFund, setErrorFetchFund] = useState("");

  const [bondData, setBondData] = useState<string[][]>([]);
  const [isFetchBond, setIsFetchBond] = useState(true);
  const [bondPage, setBondPage] = useState(1);
  const [maxBondPage, setMaxBondPage] = useState(1);
  const [errorFetchBond, setErrorFetchBond] = useState("");
  const [sortDataBond, setSortDataBond] = useState("");

  const prevParamsRef = useRef({
    sortData: '',
    searchText: '',
    selectedStock: '',
    selectedBond: '',
    filterType: '',
  });

  useEffect(() => {
    fetchStockList();
    fetchBondList();
  }, []);

  useEffect(() => {
    if (!showDetail) {
      const portofolioPerformanceAccountInfoInterface: portofolioPerformanceAccountInfoInterface =
        {};
      setAccountInfo(portofolioPerformanceAccountInfoInterface);
      setDetailType("stock");
    }
  }, [showDetail]);

  useEffect(() => {
    const {
      sortData: prevSortData,
      searchText: prevSearchText,
      selectedStock: prevSelectedStock,
      selectedBond: prevSelectedBond,
      filterType: prevFilterType,
    } = prevParamsRef.current;

    if (
      sortData !== prevSortData ||
      searchText !== prevSearchText ||
      selectedStock !== prevSelectedStock ||
      selectedBond !== prevSelectedBond ||
      filterType !== prevFilterType
    ) {
      if (searchText.length > 2 || searchText.length === 0) {
        fetchData();
      }
    }
    prevParamsRef.current = { sortData, searchText, selectedStock, selectedBond, filterType };
  }, [debounceSearchText, sortData, selectedStock, selectedBond, filterType]);

  useEffect(() => {
    if (isStringExist(sessionStorage.getItem("access_token")) && showDetail) {
        if (detailType === "stock" && sortDataStock) {
          fetchDetailData();
        }

        if (detailType === "fund" && sortDataFund) {
          fetchDetailDataFund();
        }

        if (detailType === "bond" && sortDataBond) {
          fetchDetailDataBond();
        }
    }
  }, [ypId, sortDataStock, detailType, showDetail, sortDataBond, sortDataFund]);

  useEffect(() => {
    setSearchText((prev) => (prev = ""));
    setSelectedStock("");
    setSortData("YpId asc")
  }, [filterType]);

  const fetchStockList = async () => {
    try {
      const response = await fetchStockListAll();
      setStockList(allStockArrString(response));
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
    }
  };

  const fetchBondList = async () => {
    try {
      const response = await fetchBondListAll();
      setBondList(allBondArrString(response));
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
    }
  };

  const fetchData = async () => {
    try {
      setAllData([]);
      setFetchingPage(1);
      setIsFetching(true);

      const response = await fetchPortofolioPerformance({
        page: 1,
        query: searchText.length > 2 ? searchText : "",
        stock: selectedStock,
        sort: sortData.length > 2 ? sortData : "",
        bond: selectedBond,
      });
      if (response.hasOwnProperty("content")) {
        setAllData(portofolioPerformanceArrString(response.content, 0));
        setFetchingPage(2);
        setMaxFetchingPage(response.totalPages);
      }
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
      // setIsFetching(false);
    } finally {
      setIsFetching(false);
    }
  };

  const nextFetch = async () => {
    try {
      setIsFetching(true);
      const response = await fetchPortofolioPerformance({
        page: fetchingPage,
        query: searchText.length > 2 ? searchText : "",
        stock: selectedStock,
        sort: sortData.length > 2 ? sortData : "",
        bond: selectedBond,
      });
      if (response.hasOwnProperty("content")) {
        setAllData([
          ...allData,
          ...portofolioPerformanceArrString(response.content, allData.length),
        ]);
        setFetchingPage(fetchingPage + 1);
        setMaxFetchingPage(response.totalPages);
      }
      setIsFetching(false);
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
      setIsFetching(false);
    }
  };

  const fetchDetailData = async () => {
    try {
      setAllDataDetail([]);
      setFetchingPageDetail(1);
      setIsFetchingDetail(true);

      const response = await fetchPortofolioPerformanceStock({
        ypId: ypId,
        page: 1,
        query: searchText.length > 2 ? searchText : "",
        sort: sortDataStock,
      });

      if (response.hasOwnProperty("content")) {
        setAccountInfo(response.content.account_info);
        setAllDataDetail(
          portofolioPerformanceStockArrString(response.content.data, 0)
        );
        setFetchingPageDetail(fetchingPageDetail + 1);
        setMaxFetchingPageDetail(response.totalPages);
      }
      // setIsFetchingDetail(false);
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
      setIsFetchingDetail(false);
    } finally {
      setIsFetchingDetail(false);
    }
  };

  const nextDetailFetch = async () => {
    try {
      setIsFetchingDetail(true);
      const response = await fetchPortofolioPerformanceStock({
        ypId: ypId,
        page: fetchingPageDetail,
        query: searchText.length > 2 ? searchText : "",
        sort: sortDataStock,
      });
      if (response.hasOwnProperty("content")) {
        setAllDataDetail([
          ...allDataDetail,
          ...portofolioPerformanceStockArrString(
            response.content.data,
            allDataDetail.length
          ),
        ]);
        setFetchingPageDetail(fetchingPageDetail + 1);
        setMaxFetchingPageDetail(response.totalPages);
      }
      setIsFetchingDetail(false);
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
      setIsFetchingDetail(false);
    }
  };

  const fetchDetailDataFund = async () => {
    try {
      setFundData([]);
      setIsFetchFund(true);
      setFundPage(1);

      const response = await fetchPortofolioPerformanceFund({
        ypId: ypId,
        page: 1,
        query: searchText.length > 2 ? searchText : "",
        sort: sortDataFund,
      });

      if (response.hasOwnProperty('content')) {
        setAccountInfo(response.content.account_info);
        setFundData(
          portofolioPerformanceFundArrString(response.content.data, 0)
        );
        setFundPage(fundPage + 1);
        setMaxFundPage(response.totalPages);
      }
    } catch (error) {
      setErrorFetchFund(apiErrorHandler(error));
      setIsFetchFund(false);
    } finally {
      setIsFetchFund(false);
    }
  };

  const nextDetailFundFetch = async () => {
    try {
      setIsFetchFund(true);
      const response = await fetchPortofolioPerformanceFund({
        ypId: ypId,
        page: fundPage,
        query: searchText.length > 2 ? searchText : "",
        sort: sortDataFund,
      });
      if (response.hasOwnProperty('content')) {
        setFundData([
          ...fundData,
          ...portofolioPerformanceFundArrString(
            response.content.data,
            fundData.length
          ),
        ]);
        setFundPage(fundPage + 1);
        setMaxFundPage(response.totalPages);
      }
      setIsFetchFund(false);
    } catch (error) {
      setErrorFetchFund(apiErrorHandler(error));
      setIsFetchFund(false);
    }
  };

  const fetchDetailDataBond = async () => {
    try {
      setBondData([]);
      setIsFetchBond(true);
      setBondPage(1);

      const response = await fetchPortofolioPerformanceBond({
        ypId: ypId,
        page: 1,
        sort: sortDataBond,
        limit: 20,
      });

      if (response.hasOwnProperty('content')) {
        setAccountInfo(response.content.account_info);
        setBondData(
          portfolioPerformanceBondArrString(response.content.data, 0)
        );
        setBondPage(bondPage + 1);
        setMaxBondPage(response.totalPages);
      }
    } catch (error) {
      setErrorFetchBond(apiErrorHandler(error));
      setIsFetchBond(false);
    } finally {
      setIsFetchBond(false);
    }
  };

  const fetchMoreBondData = async () => {
    try {
      setIsFetchBond(true);
      const response = await fetchPortofolioPerformanceBond({
        ypId: ypId,
        page: bondPage,
        sort: sortDataBond,
        limit: 20,
      });
      if (response.hasOwnProperty('content')) {
        setBondData([
          ...bondData,
          ...portfolioPerformanceBondArrString(
            response.content.data,
            bondData.length
          ),
        ]);
        setBondPage(bondPage + 1);
        setMaxBondPage(response.totalPages);
      }
      setIsFetchBond(false);
    } catch (error) {
      setErrorFetchBond(apiErrorHandler(error));
      setIsFetchBond(false);
    }
  };

  const handleSortDataBond = (
    header: string = "CustomerValue",
    direction: string = "desc"
  ) => {
    if (header && direction) {
      setSortDataBond(header + ' ' + direction);
      setBondPage(1);
      setMaxBondPage(1);
    }
  }

  const handleBondTab = () => {
    setDetailType('bond');
    setBondPage(1);
    setSortDataBond('');
    setMaxBondPage(1);
  };

  return {
    searchText,
    sortData,
    setSortData,
    showDetail,
    setShowDetail,
    setSearchText,
    filterType,
    setFilterType,
    allData,
    isFetching,
    nextFetch,
    fetchingPage,
    maxFetchingPage,
    allDataDetail,
    nextDetailFetch,
    isFetchingDetail,
    fetchingPageDetail,
    maxFetchingPageDetail,
    fetchDetailData,
    setYpId,
    accountInfo,
    setSortDataStock,
    stockList,
    detailType,
    setDetailType,
    fetchDetailDataFund,
    nextDetailFundFetch,
    setSelectedStock,
    handleBondTab,
    fetchMoreBondData,
    isFetchBond,
    bondData,
    setBondPage,
    bondPage,
    maxBondPage,
    setSortDataBond,
    handleSortDataBond,
    setSortDataFund,
    fundData,
    isFetchFund,
    fundPage,
    maxFundPage,
    setFundPage,
    setMaxFundPage,
    setFetchingPageDetail,
    setMaxFetchingPageDetail,
    selectedStock,
    bondList,
    setSelectedBond,
    selectedBond,
  };
};

export default PortoPerformanceHooks;
