import dayjs from 'dayjs'
import { api } from './config'

export const getPerformanceDetail = async (month: string, query: string) => {
    try {
        const response = await api.get('/v1/pls/backend/investing-performances/portfolio',
            {
            params: {
                monthAndYear: dayjs(month).format('MM-YYYY'),
                query: query
            },
        })
        return response.data.data
    } catch (error) {
        throw error
    }
}