import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ProgressBar } from '@components/atom';
import { detailProfileInterface } from '@interface/detailprofile';
import { capitalize, numberFormatter } from '@utils/formatter';

interface props {
  loading: boolean;
  data: detailProfileInterface;
}
export default function portfolioTab(props: props) {
  return (
    <div className="profile-content">
      <div className="portfolio-section">
        <div className="row">
          <div className="label">
            <span>Name</span>
          </div>
          <div className="value">
            {props.loading ? (
              <Skeleton height={16} width={58} />
            ) : (
              <span> {capitalize(props.data['name']) || '-'}</span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="label text-right">
            <span>Total Asset</span>
          </div>
          <div className="value text-right">
            {props.loading ? (
              <Skeleton height={16} width={58} />
            ) : (
              <span>{numberFormatter(props.data['totalAsset']) || 0}</span>
            )}
          </div>
        </div>
      </div>

      <div className="progress-section">
        <div className="portfolio-section">
          <div className="row px-10">
            <div className="label">
              <span>Mutual Fund</span>
            </div>
          </div>

          <div className="row px-10">
            {props.loading ? (
              <Skeleton height={16} width={58} />
            ) : (
              <div className="value">
                <span>{numberFormatter(props.data['aua']) || 0}</span>
                <span className="bg-text">{` (${
                  props.data['auaFundPercent'] || 0
                }%)`}</span>
              </div>
            )}
          </div>
        </div>

        <div className="row px-10 mt-10">
          {props.loading ? (
            <Skeleton height={16} />
          ) : (
            <ProgressBar
              bgcolor="#043B72"
              completed={Math.max(
                0,
                Math.min(props.data['auaFundPercent'], 100)
              )}
            />
          )}
        </div>

        <div className="portfolio-section">
          <div className="row px-10">
            <div className="label">
              <span>Stocks</span>
            </div>
          </div>

          <div className="row px-10">
            {props.loading ? (
              <Skeleton height={16} width={58} />
            ) : (
                <div className="value">
                  <span>{numberFormatter(props.data['stock']) || 0}</span>
                  <span className="bg-text">{` (${
                    props.data['stockPercent'] || 0
                  }%)`}</span>
                </div>
            )}
          </div>
        </div>

        <div className="row px-10 mt-10">
          {props.loading ? (
            <Skeleton height={16} />
          ) : (
            <ProgressBar
              bgcolor="#FFD0A7"
              completed={Math.max(0, Math.min(props.data['stockPercent'], 100))}
            />
          )}
        </div>

        <div className="portfolio-section">
          <div className="row px-10">
            <div className="label">
              <span>Bond</span>
            </div>
          </div>

          <div className="row px-10">
            {props.loading ? (
              <Skeleton height={16} width={58} />
            ) : (
              <div className="value">
                <span>{numberFormatter(props.data['bonds']) || 0}</span>
                <span className="bg-text">{` (${
                  props.data['bondsPercent'] || 0
                }%)`}</span>
              </div>
            )}
          </div>
        </div>
        <div className="row px-10 mt-10">
          {props.loading ? (
            <Skeleton height={16} />
          ) : (
            <ProgressBar
              bgcolor="#4880EE"
              completed={Math.max(0, Math.min(props.data['bondsPercent'], 100))}
            />
          )}
        </div>

        <div className="portfolio-section">
          <div className="row px-10">
            <div className="label">
              <span>RDN Cash</span>
            </div>
          </div>

          <div className="row px-10">
            {props.loading ? (
              <Skeleton height={16} width={58} />
            ) : (
              <div className="value">
                <span>{numberFormatter(props.data['rdnCash']) || 0}</span>
                <span className="bg-text">{` (${
                  props.data['rdnCashPercent'] || 0
                }%)`}</span>
              </div>
            )}
          </div>
        </div>
        <div className="row px-10 mt-10">
          {props.loading ? (
            <Skeleton height={16} />
          ) : (
            <ProgressBar
              bgcolor="#9E9E9E"
              completed={Math.max(
                0,
                Math.min(props.data['rdnCashPercent'], 100)
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}
