import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { sumInvtoArrString, sumInvtoArrStringWindex } from "@utils/formatter";
import { fetchSummaryInv, fetchTopBottomSumInv } from "@services/sumInvApi";
import { apiErrorHandler } from "@utils/errorHandler";
import { sumInvTopBottom, summaryInvestingInterface } from "@interface/summaryInvesting";
import { isStringExist } from "@utils/validation";
import useDebounce from "./useDebounce";


const SumInvHooks = () => {
  const [month, setMonth] = useState<string>(dayjs().format("YYYY-MM"));
  const [allData, setAllData] = useState<string[][]>([]);
  const [dataArr, setDataArr] = useState<summaryInvestingInterface[]>([]);
  const [isTopBottom, setIsTopBottom] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchingPage, setFetchingPage] = useState(1);
  const [maxFetchingPage, setMaxFetchingPage] = useState(1);
  const [fetchigErrorType, setfetchigErrorType] = useState("");
  const [topBottomData, setTopBottomData] = useState<sumInvTopBottom>({
    top: [],
    bottom: [],
  });
  const [transactionValueTotal, setTransactionValueTotal] = useState(0);

  const [searchText, setsearchText] = useState("");
  const debounceSearchText = useDebounce(searchText, 500);

  const [sortData, setSortData] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    if (isStringExist(sessionStorage.getItem("access_token"))) {
      if (isTopBottom) {
        fetchTopBottom();
      } else if (
        searchText.length > 2 ||
        searchText.length === 0 ||
        (sortData && sortDirection)
      ) {
        fetchSumInvData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchText, month, isTopBottom, sortData, sortDirection]);

  const fetchSumInvData = async () => {
    try {
      setAllData([]);
      setDataArr([]);
      setFetchingPage(1);
      setIsFetching(true);
      setTransactionValueTotal(0);
      setTopBottomData({ top: [], bottom: [] });
      setfetchigErrorType("");

      const sumInvData = await fetchSummaryInv({
        month,
        page: 1,
        query: searchText.length > 2 ? searchText : "",
        sort: sortData && sortDirection ? sortData + " " + sortDirection : "",
      });
      if (sumInvData.hasOwnProperty("content")) {
        setAllData(sumInvtoArrString(sumInvData.content.data, 0));
        setDataArr(sumInvData.content.data)
        setTransactionValueTotal(sumInvData.content.total_transaction_value);
        setFetchingPage(2);
        setMaxFetchingPage(sumInvData.totalPages);
      }
      setIsFetching(false);
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
      setIsFetching(false);
      // do something on error
    }
  };

  const nextSumInv = async () => {
    setfetchigErrorType("");
    try {
      setIsFetching(true);
      const sumInvData = await fetchSummaryInv({
        month,
        page: fetchingPage,
        query: searchText.length > 2 ? searchText : "",
        sort: sortData && sortDirection ? sortData + " " + sortDirection : "",
      });
      if (sumInvData.hasOwnProperty("content")) {
        setAllData([
          ...allData,
          ...sumInvtoArrString(sumInvData.content.data, allData.length),
        ]);
        setDataArr([
          ...dataArr,
          ...sumInvData.content.data,
        ])
        setFetchingPage(fetchingPage + 1);
        setMaxFetchingPage(sumInvData.totalPages);
      }
      setIsFetching(false);
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
      setIsFetching(false);
      // do something on error
    }
  };

  const fetchTopBottom = async () => {
    setfetchigErrorType("");
    try {
      setTopBottomData({ top: [], bottom: [] });
      setIsFetching(true);
      const sumInvData = await fetchTopBottomSumInv({
        month,
      });
      setTopBottomData({
        bottom: sumInvtoArrStringWindex(sumInvData.Bottom),
        top: sumInvtoArrStringWindex(sumInvData.top),
      });
    } catch (error) {
      setfetchigErrorType(apiErrorHandler(error));
      // do something on error
    } finally {
      setIsFetching(false);
    }
  };
  return {
    month,
    setMonth,
    allData,
    setAllData,
    isTopBottom,
    setIsTopBottom,
    fetchTopBottom,
    isFetching,
    setIsFetching,
    fetchingPage,
    setFetchingPage,
    maxFetchingPage,
    setMaxFetchingPage,
    searchText,
    setsearchText,
    fetchSumInvData,
    nextSumInv,
    fetchigErrorType,
    topBottomData,
    sortData,
    setSortData,
    sortDirection,
    setSortDirection,
    transactionValueTotal,
    setDataArr,
    dataArr,
  };
};

export default SumInvHooks;
