export const summaryInvestingTableHeader = [
  "No",
  "YP ID",
  "Name",
  "Branch",
  "Manager",
  "Est date",
  "Contact date",
  "Initial Deposit",
  "Transaction Value",
  "Fee",
  "AUA",
  "Bonds",
  "RDN Cash",
  "Navi Registration",
  "Asset Value",
];

export const summaryInvestingTableFilter = [
  { label: "No", field: "No" },
  { label: "YP ID", field: "YpId" },
  { label: "Name", field: "Name" },
  { label: "Branch", field: "BranchName" },
  { label: "Manager", field: "ManagerName" },
  { label: "Est date", field: "EstDate" },
  { label: "Initial Deposit", field: "InitialDeposit" },
  { label: "Transaction Value", field: "TransactionValue" },
  { label: "Fee", field: "Fee" },
  { label: "AUA", field: "Aua" },
  { label: "Bonds", field: "Bonds" },
  { label: "RDN Cash", field: "RdnCash" },
  { label: "Navi Registration", field: "NaviRegistration" },
  { label: "Asset Value", field: "AssetValue" },
];

export const profileData = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'Contact Number',
    value: 'contactNumber',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Jobs',
    value: 'jobs',
  },
  {
    label: 'Contact Date',
    value: 'name',
    type: 'picker',
  },
  {
    label: 'Date of Birth',
    value: 'dateOfBirth',
    type: 'date',
  },
  {
    label: 'Last Transaction Date',
    value: 'lastTransactionDate',
    type: 'date',
  },
];

export interface summaryInvestingInterface {
  ypId: string;
  name: string;
  estDate: string;
  contactDate: string;
  initialDeposit: number;
  transactionValue: number;
  fee: number;
  aua: number;
  bonds: number;
  rdnCash: number;
  naviRegistration: string;
  assetValue: number;
  managerName: string;
  branchName: string;
}

export interface summaryInvestingInterfaceWindex {
  no: number;
  ypId: string;
  name: string;
  estDate: string;
  initialDeposit: number;
  transactionValue: number;
  fee: number;
  aua: number;
  bonds: number;
  rdnCash: number;
  naviRegistration: string;
  assetValue: number;
  managerName: string;
  branchName: string;
  contactDate: string;
}

export interface sumInvTopBottom {
  top: string[][];
  bottom: string[][];
}

export interface BlockUnblockItem {
  account_no: string,
  contact_date: string,
}

export interface BlockUnblockResponse {
  status: number;
  message: string;
  data: BlockUnblockItem;
}
