interface props {
    title?: string
    onClickItem: any
    isActive: boolean
}

const Tab = (props: props) => {
    return (
        <div className={props.isActive ? "tab-item-selected" : "tab-item"} onClick={props.onClickItem}>
            <p>{props.title}</p>
        </div>
    );
};
export default Tab;