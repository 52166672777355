const getClarityScript = (clarityId, employeeId) => {
  if (!clarityId) {
    console.error('Clarity ID is not defined.');
    return '';
  }
  return `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/" + ${JSON.stringify(
          clarityId
        )};
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        t.onload = function() {
          if (c['clarity']) {
          // Custom ID
            c['clarity']('identify', '${employeeId}');
          } else {
            console.error('Clarity is not initialized.');
          }
        };
        
    })(window, document, "clarity", "script");
  `;
};

const getGoogleAnalyticsScript = (gaId) => {
  if (!gaId) {
    console.error("Google Analytics ID is not defined.");
    return "";
  }
  return `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gaId}');
    `;
};

const clarityScript = getClarityScript(
  process.env.REACT_APP_CLARITY_ID,
  sessionStorage.getItem('employee_id')
);
const googleAnalyticsScript = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`;
const googleAnalyticsInitScript = getGoogleAnalyticsScript(
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID
);

export { clarityScript, googleAnalyticsInitScript, googleAnalyticsScript };

