import Tab from "@components/atom/tab";
import { DetailProfileTab, PortfolioTab } from "@components/organism";
import { Watermark } from "@hirohe/react-watermark";
import { summaryInvestingInterface } from "@interface/summaryInvesting";
import { getPerformanceDetail } from "@services/getDetail";
import { sumInvtoArrString } from "@utils/formatter";
import { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";

interface props {
  query: string;
  date: string;
  isShow: boolean;
  onClose: () => void;
  setData: (value: summaryInvestingInterface[]) => void; 
  data: summaryInvestingInterface[];
  setAllData: (value: string[][]) => void;
  fetchTopBottom: ()=> void;
  topBottomData: any;
}

export default function PopUpDetail(props: props) {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const allMenu = ["Portfolio", "Detail Profile"];
  const [activeMenu, setActiveMenu] = useState(0);
  const [refetch, setRefetch] = useState(false);

  const handleOnClickTab = (index: number) => {
    setActiveMenu(index);
  };

  useEffect(() => {
    const getDetail = async () => {
      try {
        setLoading(true);
        const result = await getPerformanceDetail(props.date, props.query);
        const modifiedResult = {
          ...result,
          account_no: props.query
        };
        
        setData(modifiedResult);
        handleUpdateArray(result.contactDate);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    setData([]);
    if (props.isShow) {
      getDetail();
    }
  }, [props.query, props.date, props.isShow, refetch]);

  const handleUpdateArray = (contactDate: string) => {
    const updatedDataArr = props.data.map((item) =>
      item.ypId === props.query ? { ...item, contactDate: contactDate } : item
    );
    props.setData(updatedDataArr);
    props.setAllData(sumInvtoArrString(updatedDataArr, 0));
  };

  return (
    <div>
      {" "}
      {props.isShow && (
        <div className="popup-overlay" onClick={() => {
          setActiveMenu(0);
          props.onClose();
          }}>
          <div className="popup-backdrop">
            <div
              className="popup-content"
              onClick={(e) => e.stopPropagation()}
              style={{
                height:
                  activeMenu === 0 ? '400px' : activeMenu === 1 ? '590px' : '',
              }}
            >
              <Watermark
                opacity={0.3}
                textSize={16}
                text={"&#160;" + sessionStorage.getItem("fullName") ?? ""}
              >
                <div className="tab-menu">
                  {allMenu.map((item, index) => (
                    <div key={index} style={{ flex: 1 }}>
                      <Tab
                        title={item}
                        isActive={activeMenu === index}
                        onClickItem={() => handleOnClickTab(index)}
                      />
                    </div>
                  ))}
                </div>

                {activeMenu === 0 && (
                  <PortfolioTab loading={loading} data={data} />
                )}
                {activeMenu === 1 && (
                  <DetailProfileTab
                    data={data}
                    loading={loading}
                    setRefetch={setRefetch}
                    refetch={refetch}
                    setActiveMenu={setActiveMenu}
                    listData={props.data}
                    setListData={props.setData}
                    setAllData={props.setAllData}
                    fetchTopBottom={props.fetchTopBottom}
                    topBottomData={props.topBottomData}
                  />
                )}
              </Watermark>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
