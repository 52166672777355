import 'react-loading-skeleton/dist/skeleton.css';
import { useEffect, useState } from 'react';
import { EyeOffIcon, EyeShowIcon } from '@assets/images';
import { detailProfileInterface } from '@interface/detailprofile';
import { DatePicker } from 'rsuite';
import PopUpConfirm from './confirmProfile';
import { updateContactDate } from '@services/sumInvApi';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { FaRegCalendar } from 'react-icons/fa6';
import {
  profileData,
  summaryInvestingInterface,
} from '@interface/summaryInvesting';
import { capitalize } from '@utils/formatter';

interface props {
  data: detailProfileInterface;
  setActiveMenu: (value: number) => void;
  setRefetch: (value: boolean) => void;
  refetch: boolean;
  loading: boolean;
  setListData: (value: summaryInvestingInterface[]) => void;
  listData: summaryInvestingInterface[];
  setAllData: (value: string[][]) => void;
  fetchTopBottom: () => void;
  topBottomData: any;
}

export default function DetailProfileTab(props: props) {
  const [showDetailProfile, setShowDetailProfile] = useState(false);
  const handleShowHideDetailProfile = () => {
    setShowDetailProfile(!showDetailProfile);
  };
  const [popUpConfirm, setPopUpConfirm] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [contactDate, setContactDate] = useState<Date | null>(null);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const dateString = props.data.contactDate;
    const dateValue = new Date(dateString);

    if (props.data.contactDate) {
      setContactDate(dateValue);
    } else {
      setContactDate(null);
    }
  }, [props.data.contactDate]);

  const fetchUpdateContactDate = async () => {
    setLoadingUpdate(true);
    setErrorMessage('');
    const body = {
      account_no: props.data.account_no,
      contact_date: dayjs(contactDate).format('YYYY-MM-DD'),
    };
    try {
      await updateContactDate(body);
      props.setRefetch(!props.refetch);
      props.setActiveMenu(1);

      setLoadingUpdate(false);
      setPopUpConfirm(false);
      setContactDate(null);
      setErrorMessage('');
      if (
        props.topBottomData?.bottom?.length ||
        props.topBottomData?.top?.length
      ) {
        props.fetchTopBottom();
      }
    } catch (error) {
      setLoadingUpdate(false);

      if (error instanceof Error) {
        setErrorMessage(error.message);
        if (error.message.includes('already exists')) {
          props.setRefetch(!props.refetch);
        }
      } else {
        setErrorMessage('Unexpected Error');
      }
    }
  };

  const disableDates = (date: Date) => {
    const today = new Date();
    const minDate = new Date('2019-12-31');
    return date < minDate || date > today;
  };

  return (
    <div className="profile-content">
      <button
        className="show-hide"
        onClick={() => handleShowHideDetailProfile()}
      >
        <img
          src={showDetailProfile ? EyeOffIcon : EyeShowIcon}
          alt="Show or Hide Content"
        />
      </button>
      {profileData.map((item) => {
        return (
          <div className="row-data">
            <div className="label">
              <span>{item.label}</span>
            </div>
            {props.loading ? (
              <Skeleton height={16} />
            ) : (
              <div className={showDetailProfile ? 'value' : 'content-blur'}>
                <span>
                  {item.type === 'date' ? (
                    dayjs(
                      props.data[item.value as keyof typeof props.data]
                    ).format('DD MMM YY')
                  ) : item.type === 'picker' ? (
                    <div className="d-flex">
                      <DatePicker
                        disabled={
                          !showDetailProfile ||
                          (props.data.contactDate ? true : false)
                        }
                        format="dd MMM yyyy"
                        className={'date-picker-custom'}
                        placeholder="Select Date"
                        oneTap
                        placement="topStart"
                        onChange={setContactDate}
                        shouldDisableDate={disableDates}
                        caretAs={FaRegCalendar}
                        value={contactDate}
                        editable={false}
                      />

                      {!props.data.contactDate ? (
                        <button
                          disabled={
                            !showDetailProfile ||
                            !props.data.account_no ||
                            !contactDate
                          }
                          onClick={() => setPopUpConfirm(!popUpConfirm)}
                          className={'save-date'}
                        >
                          Save
                        </button>
                      ) : null}
                    </div>
                  ) : item.value === 'name' ? (
                    capitalize(
                      props.data[
                        item.value as keyof typeof props.data
                      ]?.toString()
                    )
                  ) : (
                    props.data[item.value as keyof typeof props.data]
                  )}
                </span>
              </div>
            )}
          </div>
        );
      })}
      <PopUpConfirm
        isShow={popUpConfirm}
        onClose={() => setPopUpConfirm(false)}
        onConfirm={fetchUpdateContactDate}
        loading={loadingUpdate}
        errorMessage={errorMessage}
      />
    </div>
  );
}
