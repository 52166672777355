import { LogoutIcon } from '@assets/images'
import MiraeLogo from '@assets/images/mirae.svg'
import { useNavigate } from 'react-router-dom'

interface props {
    showprofile?: boolean
}

export default function Navbar(props: props) {
    const navigate = useNavigate()
    const onLogOut = () => {
        sessionStorage.clear()
        navigate('/')
    }
    return (
        <div className="nav-bar">
            <div className="d-flex align-center">
                <img
                    alt="mirae-logo"
                    src={MiraeLogo}
                    width={0}
                    height={0}
                    className="logo"
                />
            </div>
            {props.showprofile && (
                <div className="d-flex align-center">
                    <div className="d-flex align-center cursor-pointer profile-data">
                        <div className="profile-image mr-8">
                            <p className="initial-name">
                                {getInitial(sessionStorage.getItem('fullName'))}
                            </p>
                        </div>
                        <p className='username'>{sessionStorage.getItem('fullName')}
                        <br/>
                        <span style={{fontSize:"10px"}}>{sessionStorage.getItem('employee_id')}</span>
                        </p>
                        {/* <div className="dropdown-content">
                            <p onClick={onLogOut} className="cursor-pointer">
                            Log out
                            </p>
                        </div> */}
                    </div>
                    <div onClick={onLogOut} className="cursor-pointer mt-4 ml-16">
                        <img src={LogoutIcon} height="13px" />
                    </div>
                </div>
            )}
        </div>
    )
}

const getInitial = (str: any) => {
    if (typeof str === 'string') {
        return str[0]
    } else {
        return ''
    }
}
