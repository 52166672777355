export const portofolioPerformanceTableHeader = [
  "No",
  "YP ID",
  "Name",
  "Stock",
  "Mutual Fund",
  "Bond"
];

export const portofolioPerformanceStockTableHeader = [
  "No",
  "Asset Value (Rp)",
  "Average Price (Rp)",
  "Last Price (Rp)",
  "Lot",
  "P/L Floating (Rp)",
  "% P/L Floating",
];

export const portofolioPerformanceFundTableHeader = [
  "No",
  "Product Name",
  "Unit",
  "Average Price (Rp)",
  "NAV",
  "Balance (Rp)",
  "P/L Floating (Rp)",
  "% P/L Floating",
];

export const portfolioPerformanceBondTableHeader = [
  "No",
  "Bond Series",
  "Nominal (Rp)",
  "Coupon P/A (%)",
  "Maturity Date",
  "Customer Value (Rp)",
  "IBPA Price (%)",
  "Current Value (Rp)",
  "Holding Periods (Days)",
  "Interest Gain (Rp)",
  "Capital Gain (Rp)",
  "Return Value",
];

export const portofolioPerformanceSearchTypeFilter = [
  { label: "", field: "" },
  // { label: "All", field: "All" },
  { label: "Name / YP ID", field: "Name / YP ID", default: true, defaultSortType: 'desc' },
  { label: "Stock", field: "Stock" },
  { label: "Mutual Fund", field: "Mutual Fund" },
  { label: "Bond", field: "Bond" },
];

export const portofolioPerformanceSearchFilter = [
  { label: "No", field: "No" },
  // { label: "All", field: "All" },
  { label: "Name", field: "Name", default: true },
  { label: "YP ID", field: "YpId" },
  { label: "Gain / Loss", field: "StockAssetProfitLoss" },
  { label: "Nominal", field: "Nominal" },
  // { label: "Loss", field: "StockAssetValue" },
];

export const portofolioPerformanceAllFilterOveriew = [
  { label: '', field: '' },
  { label: 'YP ID', field: 'YpId', default: true, defaultSortType: 'asc' },
  { label: 'Name', field: 'Name', defaultSortType: 'desc' },
  {
    label: 'Gain/Loss (Stock)',
    field: 'StockAssetProfitLossPercentage',
    defaultSortType: 'desc',
  },
  {
    label: 'Gain/Loss (Mutual Fund)',
    field: 'FundAssetProfitLossPercentage',
    defaultSortType: 'desc',
  },
  { label: 'Nominal Bond', field: 'BondsNominal', defaultSortType: 'desc' },
];

export const portofolioPerformanceStockFilterOverview = [
  { label: '', field: '' },
  { label: 'YP ID', field: 'YpId', default: true, defaultSortType: 'asc' },
  { label: 'Name', field: 'Name', defaultSortType: 'desc' },
  {
    label: 'Gain/Loss',
    field: 'StockAssetProfitLossPercentage',
    defaultSortType: 'desc',
  },
];

export const portofolioPerformanceFundFilterOverview = [
  { label: '', field: '' },
  { label: 'YP ID', field: 'YpId', default: true, defaultSortType: 'asc' },
  { label: 'Name', field: 'Name', defaultSortType: 'desc' },
  {
    label: 'Gain/Loss',
    field: 'FundAssetProfitLossPercentage',
    defaultSortType: 'desc',
  },
];

export const portofolioPerformanceBondFilterOverview = [
  { label: '', field: '' },
  { label: 'YP ID', field: 'YpId', default: true, defaultSortType: 'asc' },
  { label: 'Name', field: 'Name', defaultSortType: 'desc' },
  { label: 'Nominal', field: 'BondsNominal', defaultSortType: 'desc' },
];

export const portofolioPerformanceStockFilter = [
  { label: "", field: "" },
  { label: "Asset Value", field: "AssetValue", default: true, defaultSortType: 'desc'},
  { label: "P/L Floating", field: "ProfitLoss", defaultSortType: 'desc' },
  { label: "% P/L Floating", field: "ProfitLossPercentage", defaultSortType: 'desc' },
];

export const portofolioPerformanceFundkFilter = [
  { label: "", field: ""},
  { label: "Balance", field: "AssetValue",  default: true, defaultSortType: 'desc'},
  { label: "P/L Floating", field: "ProfitLoss", defaultSortType: 'desc' },
  { label: "% P/L Floating", field: "ProfitLossPercentage", defaultSortType: 'desc' },
]

export const portofolioPerformanceBondFilter = [
  { label: "", field: "" },
  { label: "Customer Value", field: "CustomerValue", defaultSortType: 'desc'},
  { label: "Maturity Date", field: "MaturityDate", defaultSortType: 'asc' },
  { label: "Current Value", field: "CurrentValue", defaultSortType: 'desc', default: true },
];

export interface portofolioPerformanceInterface {
  ypid: string;
  name: string;
  stockAssetValue: string;
  stockAssetProfitLoss: string;
  stockAssetProfitLossPercentage: string;
  fundAssetValue: string;
  fundAssetProfitLoss: string;
  fundAssetProfitLossPercentage: string;
  bondsAssetValue: string;
  bondsAssetProfitLoss: string;
  bondsAssetProfitLossPercentage: string;
  bondsNominal: number,
}

export interface portofolioPerformanceStockInterface {
  item_code: string;
  asset_value: string;
  last_price: number;
  lot: number;
  avgPrice: number;
  profit_loss: number;
  profit_loss_percentage: number;
}

export interface portofolioPerformanceFundInterface {
  product_name: string;
  unit: string;
  avg_price: number;
  nav: number;
  asset_value: number;
  profit_loss: number;
  profit_loss_percentage: number;
}

export interface bondState {
  data: string[][];
  page: number;
  isFetching: boolean;
  fetchError: string;
  maxFetchPage: number;
}
export interface portfolioPerformanceBondInterface {
  item_code: string;
  nominal: number;
  coupon_pa: number;
  maturity_date: string;
  customer_value: number;
  ibpa_price: number;
  current_value: number,
  holding_periods: number;
  interest_gain: number;
  capital_gain: number;
  return_value: number,
  return_percentage: number,
}

export interface allStockInterface {
  stockCode: string;
}

export interface allBondInterface {
  bond_code: string;
}

export interface portofolioPerformanceAccountInfoInterface {
  yp_id?: string;
  name?: string;
  rdn_cash?: number;
  total_asset?: number;
  account_type?: string;
  rtl?: number;
  used_funding_ratio? : number;
}
