interface props {
  show?: boolean
}
export default function spinner(props: props) {
  return (
    <div className="spinner-box">
      {props.show && <span className="spinner"></span>}
    </div>
  )
}
