import dayjs from "dayjs";
import { api } from "./config";

interface fetchDailyNaviTransactionProps {
  month: string;
}

interface fetchDailyNaviTransactionDetailProps {
  page?: number;
  date: string;
  sort?: string;
  query?: string;
  transactionType?: string;
}

export const fetchDailyNaviTransactionAPI = async (
  props: fetchDailyNaviTransactionProps
) => {
  try {
    const response = await api.get("/v1/pls/backend/navi-transactions/summary",
      {
      params: {
        monthAndYear: dayjs(props.month).format("MM-YYYY"),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDailyNaviTransactionDetailAPI = async (
  props: fetchDailyNaviTransactionDetailProps
) => {
  try {
    const response = await api.get("/v1/pls/backend/navi-transactions/detail",
      {
      params: {
        date: dayjs(props.date).format("DD-MM-YYYY"),
        scrollId: props.page,
        query: props.query,
        sort: props.sort,
        transactionType: props.transactionType,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
