import { BlockUnblockItem, BlockUnblockResponse } from '@interface/summaryInvesting'
import axios from 'axios'
import dayjs from 'dayjs'
import { api } from './config'

interface fetchSummaryInvProps {
    month: string,
    page: number,
    query: string,
    sort?: string
}

export const fetchSummaryInv = async (props: fetchSummaryInvProps) => {
    try {
        const response = await api.get('/v1/pls/backend/investing-performances/summary',
            {
              params: {
                monthAndYear: dayjs(props.month).format('MM-YYYY'),
                scrollId: props.page,
                query: props.query,
                sort: props.sort
            },
          }
        )
        return response.data.data
    } catch (error) {
        throw error
    }
}

interface fetchTopBottomSumInvProps {
    month: string,
}
export const fetchTopBottomSumInv = async (props: fetchTopBottomSumInvProps) => {
    try {
        const response = await api.get('/v1/pls/backend/investing-performances/top-bottom',
            {
            params: {
                monthAndYear: dayjs(props.month).format('MM-YYYY'),
            }
          }
        )
        return response.data.data
    } catch (error) {
        throw error
    }
}

export const updateContactDate = async (
  data: BlockUnblockItem
): Promise<BlockUnblockResponse> => {
  try {
    const response = await api.post('/v1/pls/backend/contact-date',
      {
        account_no: data.account_no,
        contact_date: data.contact_date,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Unexpected status code');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data?.data ||
        error.response?.data?.message ||
          error.response?.data?.error ||
          'An error occurred'
      );
    } else {
      throw new Error('Unexpected error occurred');
    }
  }
};