import { Table } from "@components/molecule";
import { numberFormatter } from "@utils/formatter";
import { BackArrow, SearchIcon } from "@assets/images";
import { useCheckTokenExpiration } from "@hooks/useCheckTokenExpiration";
import SortDropdown from "./sortDropdown";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "@components/atom";
import PortoPerformanceHooks from "@hooks/portoPerformanceHooks";
import {
  portfolioPerformanceBondTableHeader,
  portofolioPerformanceAllFilterOveriew,
  portofolioPerformanceBondFilter,
  portofolioPerformanceBondFilterOverview,
  portofolioPerformanceFundFilterOverview,
  portofolioPerformanceFundTableHeader,
  portofolioPerformanceFundkFilter,
  portofolioPerformanceSearchTypeFilter,
  portofolioPerformanceStockFilter,
  portofolioPerformanceStockFilterOverview,
  portofolioPerformanceStockTableHeader,
  portofolioPerformanceTableHeader,
} from "@interface/portofolioPerformance";
import { useEffect } from "react";
import { Watermark } from "@hirohe/react-watermark";

export default function PortofolioPerformanceTabData() {
  useCheckTokenExpiration();

  const {
    searchText,
    setSortData,
    showDetail,
    setShowDetail,
    filterType,
    setFilterType,
    setSearchText,
    allData,
    isFetching,
    nextFetch,
    fetchingPage,
    maxFetchingPage,
    allDataDetail,
    nextDetailFetch,
    isFetchingDetail,
    fetchingPageDetail,
    maxFetchingPageDetail,
    setYpId,
    accountInfo,
    stockList,
    setSortDataStock,
    detailType,
    setDetailType,
    nextDetailFundFetch,
    setSelectedStock,
    handleBondTab,
    fetchMoreBondData,
    isFetchBond,
    bondData,
    bondPage,
    maxBondPage,
    handleSortDataBond,
    setSortDataFund,
    fundData,
    isFetchFund,
    fundPage,
    maxFundPage,
    setFundPage,
    setMaxFundPage,
    setFetchingPageDetail,
    setMaxFetchingPageDetail,
    sortData,
    selectedStock,
    bondList,
    setSelectedBond,
    selectedBond,
  } = PortoPerformanceHooks();

  const handleSortData = (header: string, direction: string) => {
    setSortData(header + " " + direction);
  };

  const handleSortDataStock = (
    header: string = 'AssetValue',
    direction: string = 'desc'
  ) => {
    if (header && direction) {
      setSortDataStock(header + ' ' + direction);
      setMaxFetchingPageDetail(1);
      setFetchingPageDetail(1);
    }
  };

  const handleSortDataFund = (
    header: string = 'AssetValue',
    direction: string = 'desc'
  ) => {
    if (header && direction) {
      setSortDataFund(header + ' ' + direction);
      setMaxFundPage(1);
      setFundPage(1);
    }
  };

  const handleChangeFilter = (value:string) => {
    setSortData("YpId asc");
    setSelectedStock("");
    setSelectedBond("");
    setSearchText("");
    setFilterType(value);
  };

  return (
    <div className="portfolio">
      <Watermark
        opacity={0.3}
        textSize={16}
        text={'&#160;' + sessionStorage.getItem('fullName') ?? ''}
      >
      {!showDetail ? (
        <div className="table-filter main-bg pv-12 ph-8 border-radius-9 d-flex align-center">
          <SortDropdown
            className={"customSort"}
            label=""
            options={portofolioPerformanceSearchTypeFilter}
            handleSortData={(value)=> handleChangeFilter(value)}
            sortBtn={false}
            placeholder="All"
            disabled={isFetching ? true  : false}
            value={filterType}
          />

          {filterType === "Name / YP ID" && (
            <div
              className={`search-box bg-white d-flex`}
              style={{ marginLeft: "16px", maxWidth: "30%" }}
            >
              <img src={SearchIcon} alt="search-icon" className="search-icon" />
              <input
                placeholder="Search by Name or YP ID"
                value={searchText}
                disabled={isFetching ? true  : false}
                onChange={(e) =>
                  setSearchText(e.target.value.replace(/\s+/g, " "))
                }
              />
            </div>
          )}

          {filterType === 'Bond' && (
            <SortDropdown
              options={bondList}
              handleSortData={(e) => setSelectedBond(e)}
              defaultOption={false}
              label=""
              search={true}
              sortBtn={false}
              placeholder="Search by Bond Code"
              disabled={isFetching ? true : false}
              value={selectedBond}
            />
          )}  

          {filterType === "Stock" && (
            <SortDropdown
              options={stockList}
              handleSortData={(e) => setSelectedStock(e)}
              defaultOption={false}
              defaultSort={"desc"}
              label=""
              search={true}
              sortBtn={false}
              placeholder="Search by Stock Code"
              disabled={isFetching ? true  : false}
              value={selectedStock}
            />
          )}

          <div className="ml-10">
          <SortDropdown
            options={
              filterType === 'Name / YP ID'
                ? portofolioPerformanceAllFilterOveriew
                : filterType === 'Stock'
                ? portofolioPerformanceStockFilterOverview
                : filterType === 'Mutual Fund'
                ? portofolioPerformanceFundFilterOverview 
                : filterType === 'Bond'
                ? portofolioPerformanceBondFilterOverview
                : portofolioPerformanceAllFilterOveriew
            }
            handleSortData={handleSortData}
            sortData={sortData}
            defaultOption={true}
            placeholder={"All"}
            defaultSort={"asc"}
            sortBtn={true}
            disabled={isFetching ? true  : false}
            value={sortData ? sortData?.split(" ")[0] : ""}
          />
          </div>
        </div>
      ) : (
        <div className="table-filter main-bg border-radius-9 pv-12 ph-8 d-flex align-center">
            <img
              onClick={() => setShowDetail(!showDetail)}
              src={BackArrow}
              alt="mirae table separator"
              className="mr-10 cursor-pointer"
            />
          <div className="info-container">
            <div className="info">
              <label htmlFor="">YP ID</label>
              <span>{accountInfo?.yp_id ? accountInfo?.yp_id : "-"}</span>
            </div>
            <div className="info">
              <label htmlFor="">Client Name</label>
              <span>{accountInfo?.name ? accountInfo?.name : "-"}</span>
            </div>
            <div className="info">
              <label htmlFor="">{`RDN Cash (T+2)`}</label>
              <span>
                {accountInfo?.rdn_cash
                  ? numberFormatter(accountInfo?.rdn_cash)
                  : 0}
              </span>
            </div>
            <div className="info">
              <label htmlFor="">Total Asset</label>
              <span>
                {accountInfo?.total_asset
                  ? numberFormatter(accountInfo?.total_asset)
                  : 0}
              </span>
            </div>
            <div className="info">
              <label htmlFor="">RTL</label>
              <span>
                {accountInfo?.rtl ? numberFormatter(accountInfo?.rtl) : 0}
              </span>
            </div>
            <div className="info">
              <label htmlFor="">
                {accountInfo?.account_type === 'Margin Trading'
                  ? 'Margin Ratio'
                  : 'Used Funding Ratio'}
              </label>
              <span>
                {accountInfo?.used_funding_ratio
                  ? `${numberFormatter(accountInfo?.used_funding_ratio)}`
                  : 0}
                %
              </span>
            </div>
          </div>
        </div>
      )}

      {!showDetail ? (
        <div className="box-container">
        <div
          className="table-container portofolio-detail column-hover"
          style={{
            minHeight: allData.length < 1 ? "65vh" : "",
          }}
        >
          <InfiniteScroll
            dataLength={allData.length}
            next={nextFetch}
            hasMore={!isFetching && fetchingPage <= maxFetchingPage}
            loader={<Spinner />}
            height={"70vh"}
          >
            <Table
              isFetching={isFetching}
              header={portofolioPerformanceTableHeader}
              onClickItem={(item) => {
                setYpId((prevState) => item[1]);
                setShowDetail(!showDetail);
              }}
              data={allData}
              numberColumn={[3, 4, 5]}
              boldColumns={[5]}
            />
          </InfiniteScroll>
        </div>
        </div>
      ) : (
        <div
          style={{
            minHeight: allDataDetail.length < 1 ? "65vh" : "",
          }}
        >
          {/* detailType, setDetailType */}
          <div className="portofolio-navigation-container">
            <button
              className={`tab-button ${
                detailType === "stock" ? "filled" : "outlined"
              }`}
              style={
                detailType === "stock" ? { border: "1px solid #f58220" } : {}
              }
              onClick={() => {
                setSortDataStock("")
                setDetailType("stock")
                setMaxFetchingPageDetail(1)
                setFetchingPageDetail(1)
              }}
            >
              Stock
            </button>
            <button
              className={`tab-button ${
                detailType === "fund" ? "filled" : "outlined"
              }`}
              style={
                detailType === "fund" ? { border: "1px solid #f58220" } : {}
              }
              onClick={() => {
                setSortDataFund("")
                setDetailType("fund")
                setFundPage(1)
                setMaxFundPage(1)
              }}
            >
              Mutual Fund
            </button>
            <button
              className={`tab-button ${
                detailType === "bond" ? "filled" : "outlined"
              }`}
              style={
                detailType === "bond" ? { border: "1px solid #f58220" } : {}
              }
              onClick={handleBondTab}
            >
              Bond
            </button>
          </div>

        {detailType === 'stock' && (
            <div className="stock-page">
              <div className="stock-sort">
                <SortDropdown
                  options={portofolioPerformanceStockFilter}
                  handleSortData={handleSortDataStock}
                  defaultOption={true}
                  defaultSort={'desc'}
                  sortBtn={true}
                />
              </div>

              <div className="box-container">
              <div className="table-container">
              <InfiniteScroll
                dataLength={allDataDetail.length}
                next={nextDetailFetch}
                hasMore={
                  !isFetchingDetail &&
                  fetchingPageDetail <= maxFetchingPageDetail
                }
                loader={<Spinner />}
                height={'70vh'}
              >
                <Table
                  isFetching={isFetchingDetail}
                  header={portofolioPerformanceStockTableHeader}
                  data={allDataDetail}
                  numberColumn={[1, 2, 3, 4, 5, 6]}
                  highlightColumns={[5, 6]}
                />
              </InfiniteScroll>
            </div>
            </div>
            </div>
          )}

        {detailType === 'fund' && (
            <div className="fund-page">
              <div className="fund-sort">
                <SortDropdown
                  options={portofolioPerformanceFundkFilter}
                  handleSortData={handleSortDataFund}
                  defaultOption={true}
                  defaultSort={'desc'}
                  sortBtn={true}
                />
              </div>
              <div className="box-container">
              <div className="table-container">
              <InfiniteScroll
                dataLength={fundData.length}
                next={nextDetailFundFetch}
                hasMore={
                  !isFetchFund &&
                  fundPage <= maxFundPage
                }
                loader={<Spinner />}
                height={'70vh'}
              >
                <Table
                  isFetching={isFetchFund}
                  header={portofolioPerformanceFundTableHeader}
                  data={fundData}
                  numberColumn={[2,3,4,5,6,7]}
                  highlightColumns={[6, 7]}
                />
              </InfiniteScroll>
              </div>
            </div>
            </div>
          )}

          {detailType === 'bond' && (
            <div className="bond-page">
              <div className="bond-sort">
                <SortDropdown
                  options={portofolioPerformanceBondFilter}
                  handleSortData={handleSortDataBond}
                  defaultOption={true}
                  defaultSort={"desc"}
                  sortBtn={true}
                />
              </div>
              <div className="box-container">
              <div className="table-container">
              <InfiniteScroll
                dataLength={bondData.length}
                next={fetchMoreBondData}
                hasMore={bondPage <= maxBondPage}
                loader={<Spinner />}
                height={"70vh"}
              >
                <Table
                  isFetching={isFetchBond}
                  header={portfolioPerformanceBondTableHeader}
                  data={bondData}
                  numberColumn={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                  highlightColumns={[11]}
                  widthColumns={[
                    '30px',
                    '100px',
                    '100px',
                    '100px',
                    '100px',
                    '130px',
                    '100px',
                    '130px',
                    '134px',
                    '130px',
                    '100px',
                    '150px',
                  ]}
                />
              </InfiniteScroll>
              </div>
            </div>
            </div>
          )}  
        </div>
      )}
      </Watermark>
    </div>
  );
}
